import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../api/ApiConstant";

import { changePageName } from "../store/actions/page.actions";
import { selectUser } from "../store/reducers/auth.reducers";
import axios from "../api/axios";
import {  useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WithdrawReport = () => {

  const navigate =useHistory();

  useEffect (()=>{
    navigate.push('Withdraw-report');

  },[0])




  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const [totalRow,setRow]=useState();

  const [initialLoading, setInitialLoading] = useState(true);
  const [reports, setReports] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Withdraw Report")), []);

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await axios.get(`${constant.withdraw.AcceptRequest}`);

        setReports(
          response.data.data
            .filter((res) => res.userId === user.id)
            .map((res) =>
              res.status === "R"
                ? { ...res, status: "Rejected" }
                : res.status === "A"
                  ? { ...res, status: "Accepted" }
                  : res.status === "P"
                    ? { ...res, status: "Pending" }
                    : res
            )
        );
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => setRow(reports.length));


  if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

  return (
    <div>
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Transaction Date</th>
                <th>Currency Type</th>
                <th>Coin (FMDC)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {reports?.map(
                ({ requestedAt, currencyType, amount, status }, index) => (
                  <tr key={index}>
                    <td>{totalRow-index}</td>
                    <td>{new Date(requestedAt).toDateString()}</td>
                    <td>{currencyType}</td>
                    <td>{amount}</td>
                    <td>{status}</td>
                  </tr>
                )
              ).reverse()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WithdrawReport;
