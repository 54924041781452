import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import app from "../../firebase";
import axios from "../../api/axios";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../../store/actions/notify.actions";
import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import { constant } from "../../api/ApiConstant";

const initialState = {
  nameInPan: "",
  panNumber: "",
};

function PanDetails() {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [panDetails, setPanDetails] = useState(initialState);
  const [isNew, setIsNew] = useState(true);

  const [image, setImage] = useState("");
  const imageRef = useRef();

  useEffect(() => {
    dispatch(changePageName("PAN Details"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchPanDetails = async () => {
      try {
        const response = await axios.get(`user/getPanRecord/for/${user.id}`);
        if (response.data.data) {
          setPanDetails(response.data.data);
          setIsNew(false);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchPanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPanDetails({ ...panDetails, [name]: value });
  };

  const handleImage = (e) => setImage(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      dispatch(removeFailure());

      if (!image) {
        setIsLoading(false);
        return dispatch(setFailure("Please add proof image"));
      }

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp)
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );

      const response = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );

      if (!response.data.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }

      new Compressor(image, {
        quality: 0.2,
        resize: "contain",
        success(result) {
          const storage = getStorage(app);
          const storageRef = ref(
            storage,
            `pan-proof-images/${uuidv4()} - ${new Date()} - ${user.refId}`
          );
          const uploadTask = uploadBytesResumable(storageRef, result);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => console.log(error),
            () =>
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  if (downloadURL) {
                    axios
                      .post("/user/panRecord/create", {
                        userId: user.id,
                        nameInPan: panDetails.nameInPan,
                        panNumber: panDetails.panNumber,
                        panImageUrl: downloadURL,
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          dispatch(setSuccess("Pan details added"));
                          setTimeout(() => {
                            dispatch(removeSuccess());
                          }, 3000);
                        }
                        setOtp("");
                        setOtpSent(false);
                        setIsNew(false);
                        imageRef.current.value = "";
                        setIsLoading(false);
                      });
                  }
                }
              )
          );
        },
        error(err) {
          setIsLoading(false);
          console.log(err.message);
        },
      });
    } catch (err) {
      setIsLoading(false);
      dispatch(setFailure(err.message));
    }
  };

  if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

  return (
    <div className="card-container">
      <form onSubmit={handleSubmit} className="bank_form">
        <div className="bank_list">
          <label className="title">Name as in the PAN CARD</label>
          <input
            type="text"
            className="bank_ipbox"
            name="nameInPan"
            value={panDetails.nameInPan}
            onChange={handleChange}
            disabled={!isNew}
            required
          />
        </div>
        <div className="bank_list">
          <label className="title">NUMBER</label>
          <input
            type="text"
            className="bank_ipbox"
            name="panNumber"
            value={panDetails.panNumber}
            onChange={handleChange}
            disabled={!isNew}
            required
          />
        </div>
        {isNew && (
          <div className="bank_list">
            <label className="title">PAN CARD</label>
            <input
              type="file"
              className="bank_ipbox"
              name="panCard"
              accept="image/*"
              ref={imageRef}
              onChange={handleImage}
            />
          </div>
        )}
        {otpSent && isNew && (
          <div className="bank_list">
            <label className="title">OTP</label>
            <input
              type="text"
              value={otp}
              className="bank_ipbox"
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
        )}
        {isNew && (
          <div className="btn_container">
            <button
              type="submit"
              className="submit_btn dark__pink"
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Submit"}
            </button>
          </div>
        )}
      </form>
      <p className="notes">
        Notes: <br />
        This is one time and irreversible step.
      </p>
    </div>
  );
}

export default PanDetails;
