import React, {useState} from "react";
import { MdDelete } from "react-icons/md";

import "./ConfimationModal.css";


const ConfirmModal = ({ yes, no, visible }) => {
  if (!visible) return null;


  return (
    <>
      <div className="modal--overlay" onClick={no}></div>
      <form onSubmit={yes} className="modal__container">
        <MdDelete fontSize="1rem" />
        <h2 className="modal__title">Are you sure?</h2>
        <p className="modal__content">This action can't be undone.</p>
        <div className="button__container">
          <button type="button" onClick={no} className="button__no">
            No
          </button>
          <button type="submit" className="button__yes">
            Yes
          </button>
        </div>
      </form>
    </>
  );
};

export default ConfirmModal;
