import pdf from '../assets/Assetfmdc.pdf';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePageName } from "../store/actions/page.actions";
// import React from "react";
import {  useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Plan = () => {
  const navigate =useHistory();

  useEffect (()=>{
    navigate.push('plan');

  },[0])

  const dispatch = useDispatch();
  useEffect(() => dispatch(changePageName("Plan")), []);
  
  return (
    <div>
      <object width="100%" height="500" data={pdf} type="application/pdf">   </object>
      </div>
  );
};

export default Plan;