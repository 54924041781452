// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7HImVVS5e6I5oOTX72fUW1IGLu3bGfdQ",
  authDomain: "freeminersui.firebaseapp.com",
  projectId: "freeminersui",
  storageBucket: "freeminersui.appspot.com",
  messagingSenderId: "775811901623",
  appId: "1:775811901623:web:c8ce4a6f8ea56420450532",
  measurementId: "G-19LSTHDKSB",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export default app;
