import React from "react";

const ComingBack = () => {
  return (
    <h2 style={{ color: "black", textAlign: "center" }}>
      Site under maintenance
    </h2>
  );
};

export default ComingBack;
