import React from "react";
import { Redirect, Route } from "react-router";

const AuthRoute = ({ path, Component, redirectTo, condition }) => {
  if (condition) return <Redirect to={redirectTo} />;

  return <Route path={path} component={Component} />;
};

export default AuthRoute;
