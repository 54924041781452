import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { changePageName } from "../../store/actions/page.actions";
import { selectUser, UPDATE_USER } from "../../store/reducers/auth.reducers";
import "./Profile.css";
import axios from "../../api/axios";
import { constant } from "../../api/ApiConstant";
import BackButton from "../../components/BackButton";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../../store/actions/notify.actions";
import {  useHistory } from "react-router-dom/cjs/react-router-dom.min";

const formatDate = (date) => {
  return new Date(date).toDateString().split(" ").slice(1).join(" ");
};

const Profile = () => {
  // const navigate =useHistory();

  // useEffect (()=>{
  //   navigate.push('/profile');

  // },[0])
  const user = useSelector((state) => selectUser(state));

  const [profile, setProfile] = useState("");
  const [data, setData] = useState("");

  const [otp, setOtp] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleLoading = (val) => setIsLoading(val);

  const handleUpdate = async () => {
    try {
      handleLoading(true);
      dispatch(removeFailure());

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp)
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );

      const response = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );

      if (!response.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }

      const values = {
        name: profile.name,
        gender: profile.gender,
        mobileNumber: profile.mobileNumber,
        dob: profile.dob,
        nominee: profile.nominee,
        relation: profile.relation,
        address: profile.address,
        city: profile.city,
        pin: profile.pin,
        country: profile.country,
      };
      const result = await axios.post(constant.user.updateUser, {
        ...values,
        id: user.id,
      });
      if (result.data.data) {
        setIsEditMode(false);
        setOtp("");
        setOtpSent(false);
        setData(values);
        dispatch(UPDATE_USER(values));
        dispatch(setSuccess("Profile updated"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
      }
    } catch (err) {
      dispatch(setFailure(err.message));
    } finally {
      handleLoading(false);
    }
  };

  useEffect(() => {
    dispatch(changePageName("Profile"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/user/getById/${user.id}`);
        setProfile(response.data.data);
        setData(response.data.data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading....</p>;
  }

  console.log(profile.dob);

  return (
    <>
      <BackButton />
      <div className="card-container">
        <div className="header__container">
          <h4 className="page-title">User profile</h4>
          <div>
            <button
              type="button"
              className={isEditMode ? "cancel__btn" : "edit__btn dark__blue"}
              onClick={() => {
                setIsEditMode(!isEditMode);
                isEditMode && setProfile(data);
              }} 
              disabled={isLoading}
            >
              {isEditMode ? "Cancel" : "Edit Profile"}
            </button>
            {isEditMode && (
              <button
                type="button"
                className="edit__btn dark__pink"
                onClick={handleUpdate}
                disabled={isLoading}
              >
                {isLoading ? "Loading" : "Update"}
              </button>
            )}
          </div>
        </div>
        <div>
          <div className="form_list">
            <label className="form_title">Name</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter Name"}
              name="name"
              value={profile.name}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <div className="form_list">
            <label className="form_title">Gender</label>
            <select
              value={profile.gender}
              onChange={handleChange}
              name="gender"
              disabled={!isEditMode}
              className="form_box"
            >
              <option value="">--select--</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
          <div className="form_list">
            <label className="form_title">Mobile</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter Mobile Number"}
              name="mobileNumber"
              value={profile.mobileNumber}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <div className="form_list">
            <label className="form_title">Email Address</label>
            <p className="form_box form__disabled">{profile?.mailId || "-"}</p>
          </div>
          <div className="form_list">
            <label className="form_title">Joined on</label>
            <p className="form_box form__disabled">
              {formatDate(profile?.joiningDate) || "-"}
            </p>
          </div>
          <br />
          <br />
          {!isEditMode ? (
            <div className="form_list">
              <label className="form_title">DOB</label>
              <p className="form_box form__disabled">
                {formatDate(profile?.dob) || "-"}
              </p>
            </div>
          ) : (
            <div className="form_list">
              <label className="form_title">DOB</label>
              <input
                type="date"
                placeholder={!isEditMode ? "-" : "Enter DOB"}
                name="dob"
                value={profile.dob}
                onChange={handleChange}
                className="form_box"
                disabled={!isEditMode}
              />
            </div>
          )}

          <div className="form_list">
            <label className="form_title">Nominee</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter Nominee"}
              name="nominee"
              value={profile.nominee}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <div className="form_list">
            <label className="form_title">Relation</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter Relation"}
              name="relation"
              value={profile.relation}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <br />
          <br />
          <div className="form_list">
            <label className="form_title">Address</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter Address"}
              name="address"
              value={profile.address}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <div className="form_list">
            <label className="form_title">City</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter City"}
              name="city"
              value={profile.city}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <div className="form_list">
            <label className="form_title">PIN</label>
            <input
              type="number"
              placeholder={!isEditMode ? "-" : "Enter PIN"}
              name="pin"
              value={profile.pin}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          <div className="form_list">
            <label className="form_title">Country</label>
            <input
              type="text"
              placeholder={!isEditMode ? "-" : "Enter Country"}
              name="country"
              value={profile.country}
              onChange={handleChange}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
          {otpSent && (
            <div className="form_list">
              <label className="form_title">OTP</label>
              <input
                type="text"
                placeholder={!isEditMode ? "-" : "Enter OTP"}
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="form_box"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Profile;
