import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../api/axios";
import { changePageName } from "../../store/actions/page.actions";
import "./BankAddress.css";
import { constant } from "../../api/ApiConstant";
import { selectUser } from "../../store/reducers/auth.reducers";
import BackButton from "../../components/BackButton";
import {
  setSuccess,
  removeSuccess,
  setFailure,
} from "../../store/actions/notify.actions";

const BankAddress = () => {
  const user = useSelector((state) => selectUser(state));
  const dispatch = useDispatch();

  const [bankAddress, setBankAddress] = useState({
    accountNumber: "",
    ifscCode: "",
    branch: "",
    bankName: "",
  });
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);
  const [isUpdateAddress, setIsUpdateAddress] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankAddress({ ...bankAddress, [name]: value });
  };

  useEffect(() => {
    dispatch(changePageName("Bank Details"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchBankAddress = async () => {
      try {
        const response = await axios.get(`${constant.bank.fetch}/${user.id}`);
        setBankAddress(response.data.data);
        setIsNew(false);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchBankAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp)
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );

      const response = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );

      if (!response.data.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }

      const result = await axios.post(constant.bank.update, {
        ...bankAddress,
        userId: user.id,
      });

      if (result.data.data) {
        setIsUpdateAddress(!isUpdateAddress);
        setOtp("");
        setOtpSent(false);
        dispatch(setSuccess("Bank address updated"));
        setTimeout(() => {
          dispatch(removeSuccess());
        }, 3000);
      }
    } catch (err) {
      dispatch(setFailure(err.message));
    } finally {
      setIsLoading(false);
    }
  };

  if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

  return (
    <>
      <BackButton />
      <div className="card-container">
        <div className="update-btn-container">
          {!isUpdateAddress ? (
            <button
              onClick={() => setIsUpdateAddress(!isUpdateAddress)}
              className="update-bank-btn dark__pink"
              disabled={isNew}
            >
              Update Bank Address
            </button>
          ) : (
            <button
              onClick={() => setIsUpdateAddress(!isUpdateAddress)}
              className="update-bank-btn cancel__btn"
              disabled={isNew}
            >
              Cancel
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit} className="bank_form">
          <div className="bank_list">
            <label className="title">Account Holder Name</label>
            <input
              type="text"
              name="accountNumber"
              value={user?.name}
              onChange={handleChange}
              disabled
              className="bank_ipbox"
              placeholder="-"
            />
          </div>
          <div className="bank_list">
            <label className="title">Account No.</label>
            <input
              type="text"
              name="accountNumber"
              value={bankAddress.accountNumber}
              onChange={handleChange}
              disabled={!isNew && !isUpdateAddress}
              className="bank_ipbox"
              placeholder="-"
            />
          </div>
          <div className="bank_list">
            <label className="title">IFSC Code</label>
            <input
              type="text"
              name="ifscCode"
              value={bankAddress.ifscCode}
              onChange={handleChange}
              disabled={!isNew && !isUpdateAddress}
              className="bank_ipbox"
              placeholder="-"
            />
          </div>
          <div className="bank_list">
            <label className="title">Branch</label>
            <input
              type="text"
              name="branch"
              value={bankAddress.branch}
              onChange={handleChange}
              disabled={!isNew && !isUpdateAddress}
              className="bank_ipbox"
              placeholder="-"
            />
          </div>
          <div className="bank_list">
            <label className="title">Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={bankAddress.bankName}
              onChange={handleChange}
              disabled={!isNew && !isUpdateAddress}
              className="bank_ipbox"
              placeholder="-"
            />
          </div>
          {otpSent && (
            <div className="bank_list">
              <label className="title">OTP</label>
              <input
                type="text"
                value={otp}
                className="bank_ipbox"
                onChange={(e) => setOtp(e.target.value)}
                disabled={!isNew && !isUpdateAddress}
              />
            </div>
          )}
          <div className="btn_container">
            {isUpdateAddress && (
              <button
                type="submit"
                className="submit__btn dark__pink"
                disabled={isLoading}
              >
                {isLoading ? "Loading" : "Submit"}
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
export default BankAddress;
