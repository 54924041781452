import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiEdit2, FiPower } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import image from "../assets/avatar.png";
import "./TopBar.css";
import { selectPage } from "../store/reducers/page.reducers";
import { REMOVED_USER } from "../store/reducers/auth.reducers";

export default function TopBar({ handleShrink, handleOpenDrawer }) {
  const pageName = useSelector((state) => selectPage(state));

  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("freecoinToken");
    dispatch(REMOVED_USER());
  };

  return (
    <div className="up-nav">
      <div className="left-main">
        <AiOutlineMenu
          className="setting pointer btn-shrink"
          onClick={handleShrink}
          color="#fff"
        />
        <AiOutlineMenu
          className="setting pointer btn-menu"
          onClick={handleOpenDrawer}
          color="#fff"
        />
           
      </div>
     
      <div className="center-main">
        <h3>{pageName}</h3>
      </div>
      <div className="right-main">
        <FiPower size="14px" className="poweroff" onClick={handleLogout} />
        <div className="avatar-container spacing-left">
          {/* <img className="pic" src={image} alt="avatar" />
          <p className="edit_btn">
            <FiEdit2 />
          </p> */}
        </div>
      </div>
    </div>
  );
}
