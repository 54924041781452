// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import BackButton from "../components/BackButton";

// import "./Withdraw.css";
// import axios from "../api/axios";
// import { constant } from "../api/ApiConstant";
// import { selectUser } from "../store/reducers/auth.reducers";
// import { changePageName } from "../store/actions/page.actions";
// import {
//   removeFailure,
//   removeSuccess,
//   setFailure,
//   setSuccess,
// } from "../store/actions/notify.actions";
// import ConfirmModal from "../components/ConfirmationModal";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// const Withdraw = () => {

//  const navigate =useHistory();

//  useEffect(()=>{

//  navigate.push('/Withdraw');
//  },[0])


              
//   const [withdraw, setWithdraw] = useState({
//     currencyType: "USD",
//     amount: "",
//   });
//   const [deduction, setDeduction] = useState({
//     fc: "",
//     amount: "",
//   });
//   const [stacking, setStacking] = useState({
//     stackingWalletFC: "",
//     stackingWalletRupee: "",
//   });
//   const [withdrawWallet, setWithdrawWallet] = useState();
//   const [tokenAddress, setTokenAddress] = useState("");
//   const [bankDetails, setBankDetails] = useState("");
//   const [stakeDate, setStakeDate] = useState("")

//   const [otp, setOtp] = useState("");
//   const [otpSent, setOtpSent] = useState(false);
//   const [verifyOtp, setVerifyOtp] =useState("")

//   const [initialLoading, setInitialLoading] = useState(true);
//   const [isLoading, setIsLoading] = useState(false);

//   const user = useSelector((state) => selectUser(state));

//   const [isModalOpen, setIsModalOpen] = useState(false);




//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setWithdraw({ ...withdraw, [name]: name === "amount" ? +value : value });
//   };

//   const handleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       handleModal();
//       setIsLoading(true);
//       dispatch(removeFailure());
      
  
//       if(stakeDate){
//       return dispatch(setFailure("Please stake and try again"));
//       }
//       // if (
//       //   !bankDetails ||S
//       //   !bankDetails.accountNumber ||
//       //   !bankDetails.ifscCode ||
//       //   !bankDetails.branch
//       //   // || !bankDetails.bankName
//       // )
//       //   return dispatch(setFailure("Please fill bank details before withdraw"));


//       if (withdraw.currencyType === "USD" && !tokenAddress)
//         return dispatch(
//           setFailure("Please set usdt address before withdraw")
//         );

//       if (
//         (withdraw.currencyType === "USD" && withdraw.amount < 50) ||
//         (withdraw.currencyType === "internal transfer" && withdraw.amount < 50) ||
//         (withdraw.currencyType === "USD" &&
//           withdraw.amount > stacking.stackingWalletFC) ||
//         (withdraw.currencyType === "internal transfer" &&
//           withdraw.amount > stacking.stackingWalletFC) ||
//         withdraw.amount <= 0
//       ) {
//         setIsLoading(false);
//         return dispatch(setFailure("Please enter valid amount"));
//       }

//       if (!otpSent) {
//         const response = await axios.post(
//           `${constant.otp.send}/${user.id}`,
//           {}
//         );
//         setOtpSent(response.data);
//         dispatch(setSuccess("OTP sent to your email. Please check it"));
//         setTimeout(() => dispatch(removeSuccess()), 1000);
//         setIsLoading(false);
//         return;
//       }

//       if (!otp) {
//         setIsLoading(false);
//         return dispatch(
//           setFailure("Please enter corresponding otp to your email")
//         );
//       }

//       const result = await axios.get(
//         `${constant.otp.verify}/${user.id}/${otp}`
//       );
//       setVerifyOtp(result.data.data)

//       if (!result.data.data) {
//         setIsLoading(false);
//         return dispatch(setFailure("Please enter valid OTP"));
//       }

//       let values;
//       if (withdraw.currencyType === 'USD') {
//         values = {
//           userId: user?.id,
//           amount: withdraw.amount,
//           currencyType: withdraw.currencyType,
//         };
//       } else if(withdraw.currencyType === 'withdraw'){
//         values = {
//           fromRefId: user?.refId,
//           amount: withdraw.amount,
//         }
//       }else if(withdraw.currencyType === 'internal transfer'){
//         values = {
//           refId:user?.refId,
//           userId : user.id,
//           currencyType : withdraw.currencyType,
//           amount: withdraw.amount,
//         }
//       }
//       // if (withdraw.currencyType === 'coin') {
//       //   const response = await axios.post(constant.withdrawRequest, values);
//       //   if (response.data) {
//       //     if (values.currencyType === "coin") {
//       //       setStacking({
//       //         ...stacking,
//       //         stackingWalletFC: stacking.stackingWalletFC - values.amount,
//       //       });
//       //     } else if (values.currencyType === "rupee") {
//       //       setStacking({
//       //         ...stacking,
//       //         stackingWalletRupee: stacking.stackingWalletRupee - values.amount,
//       //       });
//       //     }

//       //     dispatch(setSuccess("Withdraw Request sent"));
//       //     setTimeout(() => {
//       //       dispatch(removeSuccess());
//       //     }, 3000);

//       //     setWithdraw({
//       //       currencyType: "coin",
//       //       amount: "",
//       //     });
//       //     setOtp("");
//       //     setOtpSent(false);
//       //   }
//       // }
//       if (withdraw.currencyType === 'USD') {
//         const response = await axios.post(constant.transferWithdraw, values);
//         if (response.data) {
//           if (values.currencyType === "USD") {
//             setStacking({
//               ...stacking,
//               stackingWalletFC: stacking.stackingWalletFC - values.amount,
//             });
//           } else if (values.currencyType === "rupee") {
//             setStacking({
//               ...stacking,
//               stackingWalletRupee: stacking.stackingWalletUSD - values.amount,
//             });
//           }

//           dispatch(setSuccess("Withdraw Request sent"));
//           setTimeout(() => {
//             dispatch(removeSuccess());
//           }, 3000);

//           setWithdraw({
//             currencyType: "coin",
//             amount: "",
//           });
//           setOtp("");
//           setOtpSent(false);
//         }
//       }
//       else if(withdraw.currencyType === 'internal transfer'){
//         const response = await axios.post(constant.internalTransferWithdraw, values);
//         if (response.data) {
//           if (values.currencyType === "USD") {
//             setStacking({
//               ...stacking,
//               stackingWalletFC: stacking.stackingWalletFC - values.amount,
//             });
//           } else if (values.currencyType === "rupee") {
//             setStacking({
//               ...stacking,
//               stackingWalletRupee: stacking.stackingWalletRupee - values.amount,
//             });
//           }
          
//           dispatch(setSuccess("Withdraw Request sent"));
//           setTimeout(() => {
//               dispatch(removeSuccess());
//             }, 3000);
            
//             setWithdraw({
//               currencyType: "internal transfer",
//               amount: "",
//             });
//             setOtp("");
//             setOtpSent(false);
//             window.location.reload();

//           }
//         }
//         console.log("minusssValue", stacking);
//     } catch (e) {
//       console.log(e)
//     } finally {
//       setIsLoading(false);
//     }
//   }

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(changePageName("Withdraw"));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     const fetchBankAddress = async () => {
//       try {
//         const response = await axios.get(`${constant.bank.fetch}/${user.id}`);
//         if (response.status === 200) setBankDetails(response.data.data);
//       } catch (error) {
//         console.error(error.message);
//       }
//     };
//     fetchBankAddress();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     const fetchStakeDate = async () => {
//       try {
//         const response = await axios.get(`${constant.stackingDate}/${user.id}`);
//         setStakeDate(response.data.message)
//       } catch (error){
//         console.error(error.message);
//       }
//     };
//     fetchStakeDate();
//   }, [])

//   useEffect(() => {
//     const fetchCoinAddress = async () => {
//       try {
//         const response = await axios.get(`/user/getFreeCoinAddress/${user.id}`);
//         if (response.data.data)
//           setTokenAddress(response.data.data.freeCoinAddress);
//       } catch (err) {
//         console.log(err);
//       }
//     };
//     fetchCoinAddress();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     const getWalletValues = async () => {
//       try {
//         const { data } = await axios.get(
//           `${constant.dashboard.wallet}/${user?.id}`
//         );
//         // setStackingWallet(data.data.stackingWalletAmountNew);
//         // SetEarningWallet(data.data.earningWalletAmountNew);
//         // SetOfferWallet(data.data.offerWalletAmountNew);
          
//         const totalStacking =
//           data.data.stackingWalletAmountNew +
//           data.data.offerWalletAmountNew +
//           data.data.earningWalletAmountNew;

//           setWithdrawWallet(totalStacking)

//         const result = await axios.get(
//           `${constant.conversion.coinToUsd}/${totalStacking}`
//         );
//         setStacking({
//           ...stacking,
//           stackingWalletFC: totalStacking,
//           stackingWalletRupee: result.data.data,
//         });

//         const response = await axios.get(
//           `/user/getDeductionAmount/${user?.id}`
//         );
//         setDeduction(response.data.data);
//       } catch (err) {
//         console.log(err);
//       } finally {
//         if (initialLoading) setInitialLoading(false);
//       }
//     };


//     getWalletValues();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

//   console.log("withdrawWallet", deduction);

//    // testing
//    const gauDollar = Math.round(stacking.stackingWalletRupee - (deduction.DeductioINRAmount ? deduction.DeductioINRAmount : 0));
//    console.log('g',gauDollar);
//    const gDraw=parseInt(withdraw.amount)
//    const finalGau=(parseInt(gauDollar)-parseInt(withdraw.amount));
//    console.log('gaufinal,withdrawWallet,stack',finalGau,withdrawWallet,stacking);

//    if(gDraw > gauDollar){
//     alert("you dont have sufficient balance")
//    }


//   return (
//     <>
//       <BackButton />
//       <div className="card-container">
//         <div className="withdraw-top">
//           <div className="balance-container">
//             <div className="balance">
//               <h4 className="balance-title">
//                 Available Balance in Withdraw Wallet
//               </h4>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <h2 className="balance-amount dark__blueText">
//                   <b>
//                     Coins -
//                      {Math.round(
//                       stacking.stackingWalletFC -
//                       (deduction.DeductionFcAmount
//                         ? deduction.DeductionFcAmount
//                         : 0)
//                     )}
//                   </b>
//                 </h2>

//                 <h2 className="balance-amount dark__blueText">
//                   <b>
//                    Usdt- 
//                     {Math.round(
//                       stacking.stackingWalletRupee -
//                       (deduction.DeductioINRAmount
//                         ? deduction.DeductioINRAmount
//                         : 0)
//                     )}
//                   </b>
//                 </h2>



//                 {/* <h2 className="balance-amount dark__blueText">
//                   <b>
//                    Usd $
//                     {Math.round(
//                       stacking.stackingWalletRupee -
//                       (deduction.DeductioINRAmount
//                         ? deduction.DeductioINRAmount
//                         : 0)
//                     )}
//                   </b>
//                 </h2> */}
//               </div>
//             </div>
//           </div>
//         </div>
//         <br />
//         <div className="withdraw-bottom">
//           <div className="withdraw-formWrapper">
//             <div className="withdraw-formGroup">
//               <label className="withdraw-formLabel">Currency Type</label>
//               <select
//                 value={withdraw.currencyType}
//                 onChange={handleChange}
//                 name="currencyType"
//                 className="withdraw-select"
//               >
//                 {/* <option value="rupee">Rupee</option> */}
//                 <option value="USD">Withdraw USDT</option>
//                 {/* <option value="coin">Withdraw USDT</option> */}
//                 <option value="internal transfer">Internal Transfer</option>
//               </select>
//             </div>
//             <div className="withdraw-formGroup">
//               <label className="withdraw-formLabel">Enter values</label>
//               <input
//                 type="number"
//                 placeholder="Enter Your Amount"
//                 className="withdraw-formInput"
//                 value={withdraw.amount}
//                 name="amount"
//                 min={1}
//                 onChange={handleChange}
//               />
//             </div>
//             {otpSent && (
//               <div className="withdraw-formGroup">
//                 <label className="withdraw-formLabel">OTP</label>
//                 <input
//                   type="text"
//                   className="withdraw-formInput"
//                   value={otp}
//                   onChange={(e) => setOtp(e.target.value)}
//                 />
//               </div>
//             )}
//           </div>
//           <button
//             type="button"
//             onClick={handleModal}
//             className="withdraw-button dark__blue"
//             disabled={!stacking.stackingWalletFC || isLoading}
//           >
//             {isLoading ? "Loading" : "Request"}
//           </button>
//         </div>
//         <p className="notes">
//           Notes: <br />
//           1.Minimum Withdrawal in COINS is 50,00,000 ( Fifty Lakhs )<br />
//           2.Transaction Fees for Coin Withdrawal is 2,00,000 ( Two Lakhs )<br />
//           {/* 3.Minimum Withdrawal in RUPEES is 500/- ( Five Hundred )<br />
//           4.Transaction Fees for Rupees Withdrawal is 50/- ( Fifty )<br /> */}
//           3.The Coin Withdrawal takes 24 Hours to success
//           <br />
//           {/* 6.The Rupee Withdrawal takes 48 Hours to success */}
//           <br />
//         </p>
//       </div>
//       <ConfirmModal visible={isModalOpen} yes={handleSubmit} no={handleModal} />
//     </>
//   );
// };

// export default Withdraw;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../components/BackButton";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { constant } from "../api/ApiConstant";
import axios from "../api/axios";
import ConfirmModal from "../components/ConfirmationModal";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../store/actions/notify.actions";
import { changePageName } from "../store/actions/page.actions";
import { selectUser } from "../store/reducers/auth.reducers";
import "./Withdraw.css";

const Withdraw = () => {
  // testlocation
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const navigate =useHistory();

  useEffect (()=>{
    navigate.push('/withdraw');

  },[0])

  
  
  // navigate.push('/withdraw');
  // const updateQueryParams=(paramName,paramValue)=>

 
              
  const [withdraw, setWithdraw] = useState({
    currencyType: "USD",
    amount: "",
  });
  const [deduction, setDeduction] = useState({
    fc: "",
    amount: "",
  });
  const [stacking, setStacking] = useState({
    stackingWalletFC: "",
    stackingWalletRupee: "",
  });
  const [withdrawWallet, setWithdrawWallet] = useState();
  const [tokenAddress, setTokenAddress] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [stakeDate, setStakeDate] = useState("")

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verifyOtp, setVerifyOtp] =useState("")

  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => selectUser(state));

  const [isModalOpen, setIsModalOpen] = useState(false);




  const handleChange = (e) => {
    const { name, value } = e.target;
    setWithdraw({ ...withdraw, [name]: name === "amount" ? +value : value });
  };

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      handleModal();
      setIsLoading(true);
      dispatch(removeFailure());
      
  
      if(stakeDate){
      return dispatch(setFailure("Please stake and try again"));
      }
      // if (
      //   !bankDetails ||S
      //   !bankDetails.accountNumber ||
      //   !bankDetails.ifscCode ||
      //   !bankDetails.branch
      //   // || !bankDetails.bankName
      // )
      //   return dispatch(setFailure("Please fill bank details before withdraw"));


      if (withdraw.currencyType === "USD" && !tokenAddress)
        return dispatch(
          setFailure("Please set usdt address before withdraw")
        );

      if (
        (withdraw.currencyType === "USD" && withdraw.amount < 20) ||
        (withdraw.currencyType === "internal transfer" && withdraw.amount < 5000000000000) ||
        (withdraw.currencyType === "USD" &&
          withdraw.amount > stacking.stackingWalletFC) ||
        (withdraw.currencyType === "internal transfer" &&
          withdraw.amount > stacking.stackingWalletFC) ||
        withdraw.amount <= 0
      ) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid amount"));
      }

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp) {
        setIsLoading(false);
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );
      }

      const result = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );
      setVerifyOtp(result.data.data)

      if (!result.data.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }

      let values;
      if (withdraw.currencyType === 'USD') {
        values = {
          userId: user?.id,
          amount: withdraw.amount,
          currencyType: withdraw.currencyType,
        };
      } else if(withdraw.currencyType === 'withdraw'){
        values = {
          fromRefId: user?.refId,
          amount: withdraw.amount,
        }
      }else if(withdraw.currencyType === 'internal transfer'){
        values = {
          refId:user?.refId,
          userId : user.id,
          currencyType : withdraw.currencyType,
          amount: withdraw.amount,
        }
      }
      // if (withdraw.currencyType === 'coin') {
      //   const response = await axios.post(constant.withdrawRequest, values);
      //   if (response.data) {
      //     if (values.currencyType === "coin") {
      //       setStacking({
      //         ...stacking,
      //         stackingWalletFC: stacking.stackingWalletFC - values.amount,
      //       });
      //     } else if (values.currencyType === "rupee") {
      //       setStacking({
      //         ...stacking,
      //         stackingWalletRupee: stacking.stackingWalletRupee - values.amount,
      //       });
      //     }

      //     dispatch(setSuccess("Withdraw Request sent"));
      //     setTimeout(() => {
      //       dispatch(removeSuccess());
      //     }, 3000);

      //     setWithdraw({
      //       currencyType: "coin",
      //       amount: "",
      //     });
      //     setOtp("");
      //     setOtpSent(false);
      //   }
      // }
      if (withdraw.currencyType === 'USD') {
        const response = await axios.post(constant.transferWithdraw, values);
        if (response.data) {
          if (values.currencyType === "USD") {
            setStacking({
              ...stacking,
              stackingWalletFC: stacking.stackingWalletFC - values.amount,
            });
          } else if (values.currencyType === "rupee") {
            setStacking({
              ...stacking,
              stackingWalletRupee: stacking.stackingWalletUSD - values.amount,
            });
          }

          dispatch(setSuccess("Withdraw Request sent"));
          setTimeout(() => {
            dispatch(removeSuccess());
          }, 3000);

          setWithdraw({
            currencyType: "coin",
            amount: "",
          });
          setOtp("");
          setOtpSent(false);
        }
      }
      else if(withdraw.currencyType === 'internal transfer'){
        const response = await axios.post(constant.internalTransferWithdraw, values);
        if (response.data) {
          if (values.currencyType === "USD") {
            setStacking({
              ...stacking,
              stackingWalletFC: stacking.stackingWalletFC - values.amount,
            });
          } else if (values.currencyType === "rupee") {
            setStacking({
              ...stacking,
              stackingWalletRupee: stacking.stackingWalletRupee - values.amount,
            });
          }
          
          dispatch(setSuccess("Withdraw Request sent"));
          setTimeout(() => {
              dispatch(removeSuccess());
            }, 3000);
            
            setWithdraw({
              currencyType: "internal transfer",
              amount: "",
            });
            setOtp("");
            setOtpSent(false);
            window.location.reload();

          }
        }
        console.log("minusssValue", stacking);
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePageName("Withdraw"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchBankAddress = async () => {
      try {
        const response = await axios.get(`${constant.bank.fetch}/${user.id}`);
        if (response.status === 200) setBankDetails(response.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchBankAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchStakeDate = async () => {
      try {
        const response = await axios.get(`${constant.stackingDate}/${user.id}`);
        setStakeDate(response.data.message)
      } catch (error){
        console.error(error.message);
      }
    };
    fetchStakeDate();
  }, [])

  useEffect(() => {
    const fetchCoinAddress = async () => {
      try {
        const response = await axios.get(`/user/getFreeCoinAddress/${user.id}`);
        if (response.data.data)
          setTokenAddress(response.data.data.freeCoinAddress);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCoinAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getWalletValues = async () => {
      try {
        const { data } = await axios.get(
          `${constant.dashboard.wallet}/${user?.id}`
        );
        // setStackingWallet(data.data.stackingWalletAmountNew);
        // SetEarningWallet(data.data.earningWalletAmountNew);
        // SetOfferWallet(data.data.offerWalletAmountNew);
          
        const totalStacking =
          data.data.stackingWalletAmountNew +
          data.data.offerWalletAmountNew +
          data.data.earningWalletAmountNew;

          setWithdrawWallet(totalStacking)

        const result = await axios.get(
          `${constant.conversion.coinToUsd}/${totalStacking}`
        );
        setStacking({
          ...stacking,
          stackingWalletFC: totalStacking,
          stackingWalletRupee: result.data.data,
        });

        const response = await axios.get(
          `/user/getDeductionAmount/${user?.id}`
        );
        setDeduction(response.data.data);
      } catch (err) {
        console.log(err);
      } finally {
        if (initialLoading) setInitialLoading(false);
      }
    };


    getWalletValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

  console.log("withdrawWallet", deduction);

   // testing
   const gauDollar = Math.round(stacking.stackingWalletRupee - (deduction.DeductioINRAmount ? deduction.DeductioINRAmount : 0));
   console.log('g',gauDollar);
   const gDraw=parseInt(withdraw.amount)
   const finalGau=(parseInt(gauDollar)-parseInt(withdraw.amount));
   console.log('gaufinal,withdrawWallet,stack',finalGau,withdrawWallet,stacking);

   if(gDraw > gauDollar){
    alert("you dont have sufficient balance")
   }

  //  if(stack )


  return (
    <>
      <BackButton />
      <div className="card-container">
        <div className="withdraw-top">
          <div className="balance-container">
            <div className="balance">
              <h4 className="balance-title">
                Available Balance in Withdraw Wallet
              </h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="balance-amount dark__blueText">
                  <b>
                    Coins -
                     {Math.round(
                      stacking.stackingWalletFC -
                      (deduction.DeductionFcAmount
                        ? deduction.DeductionFcAmount
                        : 0)
                    )}
                  </b>
                </h2>

                <h2 className="balance-amount dark__blueText">
                  <b>
                   Usdt- 
                    {Math.round(
                      stacking.stackingWalletRupee -
                      (deduction.DeductioINRAmount
                        ? deduction.DeductioINRAmount
                        : 0)
                    )}
                  </b>
                </h2>



                {/* <h2 className="balance-amount dark__blueText">
                  <b>
                   Usd $
                    {Math.round(
                      stacking.stackingWalletRupee -
                      (deduction.DeductioINRAmount
                        ? deduction.DeductioINRAmount
                        : 0)
                    )}
                  </b>
                </h2> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="withdraw-bottom">
          <div className="withdraw-formWrapper">
            <div className="withdraw-formGroup">
              <label className="withdraw-formLabel">Currency Type</label>
              <select
                value={withdraw.currencyType}
                onChange={handleChange}
                name="currencyType"
                className="withdraw-select"
              >
                {/* <option value="rupee">Rupee</option> */}
                <option value="USD">Withdraw USDT</option>
                {/* <option value="coin">Withdraw USDT</option> */}
                <option value="internal transfer">Internal Transfer</option>
              </select>
            </div>
            <div className="withdraw-formGroup">
              <label className="withdraw-formLabel">Enter values</label>
              <input
                type="number"
                placeholder="Enter Your Amount"
                className="withdraw-formInput"
                value={withdraw.amount}
                name="amount"
                min={1}
                onChange={handleChange}
              />
            </div>
            {otpSent && (
              <div className="withdraw-formGroup">
                <label className="withdraw-formLabel">OTP</label>
                <input
                  type="text"
                  className="withdraw-formInput"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
            )}
          </div>
          <button
            type="button"
            onClick={handleModal}
            className="withdraw-button dark__blue"
            disabled={!stacking.stackingWalletFC || isLoading}
          >
            {isLoading ? "Loading" : "Request"}
          </button>
        </div>
        <p className="notes">
          Notes: <br />
          1.Minimum Withdrawal in COINS is 20 ( USDT )<br />
          {/* 2.Transaction Fees for Coin Withdrawal is 2,00,000 ( Two Lakhs )<br /> */}
          {/* 3.Minimum Withdrawal in RUPEES is 500/- ( Five Hundred )<br />
          4.Transaction Fees for Rupees Withdrawal is 50/- ( Fifty )<br /> */}
          2.The Coin Withdrawal takes 48 Hours to success
          <br />
          {/* 6.The Rupee Withdrawal takes 48 Hours to success */}
          <br />
        </p>
      </div>
      <ConfirmModal visible={isModalOpen} yes={handleSubmit} no={handleModal} />
    </>
  );
};

export default Withdraw;