import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changePageName } from "../store/actions/page.actions";
import axios from "../api/axios";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/auth.reducers";
import "./CoinAddress.css";
import { constant } from "../api/ApiConstant";

import {
  removeSuccess,
  setFailure,
  setSuccess,
} from "../store/actions/notify.actions";

let initialState = "";

const FmdcCoinUpdate = () => {
  const [tokenAddress, setTokenAddress] = useState(initialState);
  const [object, setObject] = useState("");
  const [otp, setOtp] = useState("");

  const [token, setToken] = useState("null");
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);

  const user = useSelector((state) => selectUser(state));
  const dispatch = useDispatch();

  console.log(isLoading);

  const openInput = () => {
    setIsEditMode(false)
  }

  const handleCreate = async () => {
      try {
      setIsLoading(true);
      const data = {
        "refId": user?.refId,
        "token": tokenAddress
      }
      const send = axios.post("user/createFmdc", data).then(
        (resp) => {
          console.log(resp, "response")
          if(resp.status === 200 ){
            setIsEditMode(true)
          }
        }
          )
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("FMDC Coin Update")), []);


  
  useEffect(() => {
   const fetchFmdc = async () => {
    try{
      const response = await axios.get("/user/getAllFmdcs")
      console.log(response?.data.data, "fmdcs");
      const data = response?.data.data.map((d) => (
        d?.refId === user?.refId ?
          setToken(d.token)
          // null
        : null
      ))
      
    } catch(err) {
      console.log(err, "fmdc error");
    }
   };
   fetchFmdc() 
  },[])

if(token === "null"){
  console.log("hii");
} else {
  console.log(token);
}

  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading....</p>;
  }


  return (
    <div className="card-container">
      <div className="header__container--1">
      {token === "null" ? 
          <button
          type="button"
          className="edit__btn dark__pink"
          onClick={isEditMode ? openInput : handleCreate}
          disabled={isLoading}
          >  
            { isLoading ? "Loading" : "Create" }
          </button>
          :
          null}
        {/* )} */}
      </div>
      <div>
        <div className="form_list">
          <label className="form_title">USDT address</label>
          {token === "null" ? 
          <input
          type="text"
          placeholder={"Enter USDT  Address"}
            name="name"
            // value={token}
            onChange={(e) => setTokenAddress(e.target.value)}
            className="form_box"
            disabled={isEditMode}
            />
          :
            <input
            type="text"
            placeholder={"Enter USDT Address"}
            name="name"
            value={token}
            className="form_box"
            disabled={true}
            />
          }
            </div>
      </div>
    </div>
  );
};

export default FmdcCoinUpdate;
