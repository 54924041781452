/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../../api/ApiConstant";
import axios from "../../api/axios";
import UserInfo from "../../components/UserInfo";
import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import "./DashboardContent.css";

const formatDate = (date) => {
  return date
    ? new Date(date).toDateString().split(" ").slice(1).join(" ")
    : "-";
};

const DashboardContent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const [deduction, setDeduction] = useState({
    fc: "",
    amount: "",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Dashboard")), []);

  const [todayPrice, setTodayPrice] = useState(0);
  const [totalStacking, setTotalStacking] = useState(0);
  const [ROS, setROS] = useState(0);
  const [stacking, setStacking] = useState({
    earningBalance: 0,
    availableBalance: 0,
    stacking: 0,
    stackingWalletFC: "",
    stackingWalletRupee: "",
  });
  const [isStacked, setIsStacked] = useState(false);

  const [totalData, setTotalData] = useState();
  const [stackingDate, setStackingDate] = useState();
  const [stakingWallet, setStackingWallet] = useState();
  const [loading, setLoading] = useState(true);
  const [earningWallet, SetEarningWallet] = useState();
  const [offerWallet, SetOfferWallet] = useState();
  const [businessMatch, setBusinessMatch] = useState();
  const [transferWallet, setTransferWallet] = useState();

  console.log(businessMatch);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.get(constant.dashboard.dailyPrice);
        setTodayPrice(response.data.data.cost);

        const response2 = await axios.get(
          `${constant.dashboard.totalStacking}/${user?.id}`
        );
        setTransferWallet(response2.data.data.availableBalance)
        // setStacking({
        //   ...stacking,
        //   availableBalance: response2.data.data.availableBalance
        //     ? response2.data.data.availableBalance
        //     : 0,
        //   earningBalance: response2.data.data.earningBalance
        //     ? response2.data.data.earningBalance
        //     : 0,
        // });
        const { data } = await axios.get(
          `${constant.dashboard.wallet}/${user?.id}`
        );
        setStackingWallet(data.data.stackingWalletAmountNew);
        SetEarningWallet(data.data.earningWalletAmountNew);
        SetOfferWallet(data.data.offerWalletAmountNew);
        setBusinessMatch(data.data.businessMatch);
        setTotalData(data.data);

        const response5 = await axios.get(
          `${constant.dashboard.ROS}/${user?.id}`
        );
        setROS(response5.data.data.roiAmount);
        setTotalStacking(response5.data.data.stackingAmount);

        const response6 = await axios.get(
          `/user/getUserStackingDate/${user.id}`
        );
        setStackingDate(response6.data.data);
        const response7 = await axios.get(
          `/user/getDeductionAmount/${user?.id}`
        );

        setDeduction(response7.data.data);
        if (response6.data.data) setIsStacked(true);

        const totalStacking =
          data.data.stackingWalletAmountNew +
          data.data.offerWalletAmountNew +
          data.data.earningWalletAmountNew;

                        
        const result = await axios.get(
          `${constant.conversion.coinToInr}/${totalStacking}`
        );
        setStacking({
          ...stacking,
          availableBalance: response2.data.data.availableBalance
            ? response2.data.data.availableBalance
            : 0,
          earningBalance: response2.data.data.earningBalance
            ? response2.data.data.earningBalance
            : 0,
          stackingWalletFC: totalStacking,
          stackingWalletRupee: result.data.data,
        });

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    FetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  console.log(loading);

  useEffect(()=>{
    axios.get(`/user/topupRequest`).then((res)=>{
      console.log("TopupRes",res)
    })
  },[])



  return (
    <>
      {loading ? (
        // <Loader />
        <p style={{ color: "black" }}>Loading...</p>
      ) : (
        <div className="dashboard-content">
         
           <div className="scroller-container">
             <p className="scroller">  </p>
          </div> 
          <UserInfo user={user} />
          <div className="info info__today">
            <h3>Coin Today For $100</h3>
            <p>{todayPrice * 4 || "0"}</p>
          </div>
          <div className="info-container">
            <div className="info dark__pink">
              <h3>Date of Joining</h3>
              <p>{formatDate(user?.joiningDate) || "19-Nov-2021"}</p>
            </div>
            <div className="info dark__blue">
              <h3>Date of Staking</h3>
              <p>{formatDate(stackingDate) || "19-Nov-2021"}</p>
            </div>
            <div className="info dark__pink">
              <h3>Total Staking (FMDC)</h3>
              <p>{Math.round(totalStacking) || "0"}</p>
            </div>
            <div className="info dark__blue">
              <h3>Daily ROA (FMDC)</h3>
              <p>{Math.round(ROS) || "0"}</p>
            </div>
            <div className="info dark__pink">
              <h3>Staking Wallet (FMDC)</h3>
              <h5>Available / Withdraw</h5>
              <p>{Math.round(stakingWallet) || "0"}</p>
            </div>
            <div className="info dark__blue">
              <h3>Pair Matching Wallet (FMDC)</h3>
              <h5>Available / Withdraw</h5>
              <p>{Math.round(earningWallet || "0")}</p>
            </div>
            <div className="info dark__pink">
              <h3>Transfer Wallet (FMDC)</h3>
              <p>{Math.round(transferWallet) || "0"}</p>
            </div>
            {/* <div className="info dark__blue">
              <h3>Offer Wallet (FMDC)</h3>
              <h5>Available / Withdraw</h5>
              <p>{offerWallet}</p>
            </div> */}
            <div className="info dark__pink">
              <h3>Total Wallet (FMDC)</h3>
              <h5>Coins</h5>
              <p>
                {Math.round(
                  stacking.stackingWalletFC -
                  (deduction.DeductionFcAmount
                    ? deduction.DeductionFcAmount
                    : 0)
                    )}
                {/* / {Math.round(
                      stacking.stackingWalletRupee -
                        (deduction.DeductioINRAmount
                          ? deduction.DeductioINRAmount
                          : 0)
                    )} */}
              </p>
            </div>
            <div className="info dark__blue">
              <h3>Left Users / Active Left Users</h3>
              <p>
                {totalData?.LeftUsers || "0"} /
                {totalData?.LeftActiveUsers || "0"}
              </p>
            </div>
            <div className="info dark__pink">
              <h3>Right Users / Active Right Users</h3>
              <p>
                {totalData?.RightUsers || "0"} /
                {totalData?.RightActiveUsers || "0"}
              </p>
            </div>
            <div className="info dark__blue">
              <h3>Left Business ($)</h3>
              <p>{totalData?.LeftBusinessAmount || "0"}</p>
            </div>
            <div className="info dark__pink">
              <h3>Right Business ($)</h3>
              <p>{totalData?.RightBusinessAmount || "0"}</p>
            </div>
            {/* <div className="info dark__pink">
              <h3>Business Matching</h3>
              <p>{businessMatch?.toFixed(2) || "0"}</p>
            </div> */}
          </div>
        </div>
      )}
     
    </>
  );
};
export default DashboardContent;
