import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../../api/ApiConstant";

import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import axios from "../../api/axios";
import BackButton from "../../components/BackButton";

const WalletReport = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));

  const [incomes, setIncomes] = useState();
  const [initialLoading, setInitialLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Wallet Report")), []);

  useEffect(() => {
    const getIncomes = async () => {
      try {
        const response = await axios.get(
          `${constant.wallet.walletReport}/${user.id}`
        );
        setIncomes(response.data.data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    getIncomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading...</p>;
  }

  if (incomes?.length === 0) {
    return (
      <p style={{ textAlign: "center", color: "white" }}>Nothing to Show</p>
    );
  }

  return (
    <>
      <BackButton />
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                <th className="hidden">Serial</th>
                {/* <th>TransID</th> */}
                <th>Date/Time</th>
                <th>Coin (FMDC)</th>
                {/* <th>Address</th> */}
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {incomes?.map(
                ({ transactionAt, amount, type, transID, message }, index) => (
                  <tr>
                    <td className="hidden">{index + 1}</td>
                    {/* <td>{transID || "-"}</td> */}
                    <td>{new Date(transactionAt).toDateString()}</td>
                    <td>{amount}</td>
                    <td>{message.replaceAll("_", " ")}</td>
                    {/* <td className="small">{message.split("/")[1]}</td> */}
                    {/* <td>{type}</td> */}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default WalletReport;
