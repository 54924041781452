import React from "react";
import "./NavBar.css";
import image from "../assets/logo.png";
import NavLink from "../components/NavLink";
import links from "../data/links";
import { Link } from "react-router-dom";
import {BiLink} from "react-icons/bi";

const NavBar = ({ isShrink }) => {
  const isShrinkCss = (val) => (isShrink ? `${val}--shrink` : val);

  return (
    <nav className={`${isShrinkCss("left")}`}>
      <div className="top">
        <div className="user-details">
          <h3 className="Asset">Asset FMDC</h3>

         
          {/* <div className={`${isShrinkCss("logo__container")}`}>
            <img src={image} alt="avatar" className="logo__rounded" />
          </div> */}
        </div>
      </div>
      <div className="bottom">
        {links.map(({ Icon, title, subLinks, link, goto, spacing }) => (
          <NavLink
            key={title}
            Icon={Icon}
            title={title}
            subLinks={subLinks}
            link={link}
            isShrink={isShrink}
            isShrinkCss={isShrinkCss}
            goto={goto}
            spacing={spacing}
          />
        ))}
        {/* <Link
          to="/register-user"
          className={`item link ${isShrinkCss("register")}`}
        >
          <FaCashRegister className="icon" />
          {!isShrink && <p>Registration</p>}
        </Link> */}
      
        {/* <Link className="bottom"
        
        to="/https://ohmswap.net">Fmdc swap</Link> */}
       <a className="swap-item" href="https://ohmswap.net"><BiLink className="swapicon"/>Fmdc swap</a> 
      </div>
     
    
    
    </nav>
  );
};

export default NavBar;
