import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePageName } from "../../store/actions/page.actions";
import axios from "../../api/axios";
import { constant } from "../../api/ApiConstant";
import { selectUser } from "../../store/reducers/auth.reducers";

const StackingWalletReport = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const [totalRow, setRow] = useState();
  const [reports, setReports] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  // const [sort,setSort] =useState()

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await axios.get(
          `${constant.wallet.stackingWalletReport}/${user.id}`
        );

        setReports(
          response.data.data.map((rep) =>
            rep.type === "C"
              ? { ...rep, type: "Credited" }
              : rep.type === "D"
              ? { ...rep, type: "Debited" }
              : rep
          )
        );

        setRow(reports.length);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Staking Wallet Report")), []);
  useEffect(() => setRow(reports.length));

  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading...</p>;
  }

  const sortedDesc = reports.sort(
    (objA, objB) =>
      Number(new Date(objA.transactionAt)) -
      Number(new Date(objB.transactionAt))
  );

  return (
    <div>
      {console.log("sortedDesc", sortedDesc)}
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Topup Date</th>
                <th>Coin (FMDC)</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {sortedDesc
                ?.map(({ transactionAt, fcAmount, message }, index) => (
                  <tr>
                    <td>{totalRow - index}</td>
                    {/* {(totalRow -index === 5)?
                  <td>Fri Jul 29 2022</td>:  */}
                    {console.log("transactionAt", transactionAt)}
                    <td>{new Date(transactionAt).toLocaleDateString()}</td>
                    {/* } */}
                    <td>{fcAmount}</td>
                    <td>{message === "ROI" ? "ROS" : "-"}</td>
                  </tr>
                ))
                .reverse()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StackingWalletReport;
