import React, { useEffect, useState } from 'react';
import { changePageName } from "../../store/actions/page.actions";
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../api/axios";
import { constant } from "../../api/ApiConstant";
import "./DirectReferrals.css";
import { selectUser } from "../../store/reducers/auth.reducers";
import BackButton from "../../components/BackButton";
import {  useHistory } from "react-router-dom/cjs/react-router-dom.min";


const TopupRequest = () => {
  const navigate =useHistory();

  useEffect (()=>{
    navigate.push('/topupRequest');

  },[0])

  const dispatch = useDispatch();
  const [referrals, setReferrals] = useState([]);
  const user = useSelector((state) => selectUser(state));
  const [totalRow,setRow]=useState();

  useEffect(() => {
    dispatch(changePageName("Stacking Report"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.post(`/user/topupRequestById`, { userId: user.id }).then((res) => {
      if (res.status === 200) {
        setReferrals(res.data)
      }
    })
  }, [])
 useEffect(() => setRow(referrals.length));

  return (
    <>
      <BackButton />
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                <th className="hidden">S.no</th>
                <th>ID</th>
                <th>Name</th>
                {/* <th>Joined On</th> */}
                {/* <th>Mobile</th> */}
                <th>Coin (FMDC)</th>
                <th>Status</th>
                <th>Approved Date</th>
                {/* <th>Coin (FC)</th> */}
              </tr>
            </thead>
            <tbody>
              {referrals?.map((referral, index) => {
                if (referral.refId) {
                  return (
                    <tr>
                      <td className="hidden">{totalRow-index}</td>
                      <td>{referral.refId || "-"}</td>
                      <td>{user.name || "-"}</td>
                      {/* <td>{referral.joiningDate || "-"}</td> */}
                      {/* <td>{referral.mobileNumber || "-"}</td> */}
                      <td>{referral.amount || "-"}</td>
                      <td>{referral.status || "-"}</td>
                      <td>{referral.respondedAt ? referral.respondedAt : '-'}</td>
                      {/* <td>{referral.side || "-"}</td> */}
                      {/* <td>{referral.amount || "-"}</td> */}
                    </tr>
                  );
                }
              }).reverse()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TopupRequest;