import React, { useEffect, useState } from "react";
import { BsChevronCompactLeft } from "react-icons/bs";
// import { Tree, TreeNode } from "react-organizational-chart";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import "./Tree.css";
import Tree from "react-d3-tree";
import instance from "../../api/axios";
import Loader from "../../components/Loader";

const BinaryDetails = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const history = useHistory();
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState(true);

  // const [referrals, setReferrals] = useState(data);

  useEffect(() => {
    setLoading(true);
    dispatch(changePageName("Tree Chart"));
    instance.get(`userCharts/${user.id}`).then((res) => {
      if (res.status === 200) {
        setChartData(res.data.output);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle
        cx="0"
        cy="10"
        r="10"
        fill={`${
          nodeDatum.name === "X"
            ? "white"
            : nodeDatum.attributes &&
              Object.keys(nodeDatum.attributes)[0].split(" ")[2]
            ? "#4530B2"
            : "#1CBEF5"
        }`}
        onClick={toggleNode}
      />
      <text strokeWidth="1" fill="blue" x="20" dy="0">
        {nodeDatum.name !== "X" && nodeDatum.name}
      </text>
      {nodeDatum.attributes && (
        <text fill="green" x="20" dy="20" strokeWidth="1">
          {`${Object.keys(nodeDatum.attributes)[0].split(" ")[1]}  
            ${
              Object.keys(nodeDatum.attributes)[0].split(" ")[2] === "(S)"
                ? "/ STACKED"
                : ""
            }`}
        </text>
      )}
    </g>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="trees-wrapper" style={{ background: "white" }}>
          <h3 className="chart-title">Tree Chart</h3>
          <button
            className="back-btn dark__blue"
            type="button"
            onClick={() => history.goBack()}
          >
            <BsChevronCompactLeft />
            Go Back
          </button>

          <div id="treeWrapper" style={{ width: "100vw", height: "100vh" }}>
            <Tree
              data={chartData}
              nodeSize={{ x: "140", y: "140" }}
              orientation="vertical"
              pathFunc={"step"}
              rootNodeClassName="node__root"
              branchNodeClassName="node__branch"
              leafNodeClassName="node__leaf"
              renderCustomNodeElement={renderRectSvgNode}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BinaryDetails;

// ${Object.keys(nodeDatum.attributes)[0].split(" ")[0] === "L"
//     ? "LEFT"
//     : "RIGHT"
// }
