import React, { useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";

import "./NavLink.css";
import pdf from "../assets/Assetfmdc.pdf";

const NavLink = ({
  Icon,
  subLinks = false,
  title,
  isShrink,
  isShrinkCss,
  link,
  goto,
  spacing = false,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const history = useHistory();

  const handleExpand = () => setIsExpand(!isExpand);

  return (
    <>
      <div
        className={`${isShrinkCss("item-container")} ${
          spacing ? "extra__spacing" : ""
        }`}
        onClick={() => {
          history.push(goto);
          handleExpand();
        }}
      >
        {link ? (
          <Link to={link} className="item link">
            <Icon className="icon" />
            {!isShrink && <p>{title}</p>}
          </Link>
        ) : (
          <div className="item">
            <Icon className="icon" />
            {!isShrink && <p>{title}</p>}
          </div>
        )}
        {!isShrink ? (
          subLinks ? (
            isExpand ? (
              <FiChevronDown />
            ) : (
              <FiChevronRight />
            )
          ) : null
        ) : null}
      </div>
      {!isShrink
        ? subLinks
          ? isExpand && (
              <div>
                {subLinks.map(({ title, link, download }) => {
                  return download ? (
                    <a
                      href={pdf}
                      key={title}
                      target="_blank"
                      rel="noreferrer"
                      className="subLink"
                    >
                      <HiOutlineArrowNarrowRight className="icon" />
                      {title}
                    </a>
                  ) : (
                    <Link to={link} className="subLink" key={title}>
                      <HiOutlineArrowNarrowRight className="icon" />
                      <p>{title}</p>
                    </Link>
                  );
                })}
              </div>
            )
          : null
        : null}
    </>
  );
};

export default NavLink;
