import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changePageName } from "../../store/actions/page.actions";
import "./ChangePassword.css";
import axios from "../../api/axios";
import { constant } from "../../api/ApiConstant";
import { selectUser } from "../../store/reducers/auth.reducers";
import BackButton from "../../components/BackButton";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../../store/actions/notify.actions";

const initialState = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePassword = () => {
  const [credentials, setCredentials] = useState(initialState);
  const [otp, setOtp] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const user = useSelector((state) => selectUser(state));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(removeFailure());

    try {
      setIsLoading(true);

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp)
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );

      const response = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );

      if (!response.data.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }

      const result = await axios.post(constant.password, {
        oldPassword: credentials.oldPassword,
        newPassword: credentials.newPassword,
        userId: user.id,
      });
      setCredentials(initialState);
      setOtp("");
      setOtpSent(false);

      if (result.data) {
        dispatch(setSuccess("Password Changed"));
        setTimeout(() => {
          dispatch(removeSuccess());
        }, 3000);
      }
    } catch (err) {
      dispatch(setFailure(err.message));
    } finally {
      setIsLoading(false);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePageName("Change Password"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackButton />
      <div className="card-container">
        <form onSubmit={handleSubmit} className="pass_form">
          <div className="pass_list">
            <label className="pass_label">Old Password</label>
            <input
              type="password"
              name="oldPassword"
              className="pass_ipbox"
              value={credentials.oldPassword}
              onChange={handleChange}
            />
          </div>

          <div className="pass_list">
            <label className="pass_label">New password</label>
            <input
              type="password"
              name="newPassword"
              className="pass_ipbox"
              value={credentials.newPassword}
              onChange={handleChange}
            />
          </div>
          <div className="pass_list">
            <label className="pass_label">Confirm New Password</label>
            <input
              type="password"
              name="confirmNewPassword"
              className="pass_ipbox"
              value={credentials.confirmNewPassword}
              onChange={handleChange}
            />
          </div>
          {otpSent && (
            <div className="pass_list">
              <label className="pass_label">OTP</label>
              <input
                type="text"
                className="pass_ipbox"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          )}
          <div className="btn_container">
            <button
              type="submit"
              className="submit_btn dark__blue"
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default ChangePassword;
