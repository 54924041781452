import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { changePageName } from "../store/actions/page.actions";
import axios from "../api/axios";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/auth.reducers";
import "./CoinAddress.css";
import { constant } from "../api/ApiConstant";
import {
  removeSuccess,
  setFailure,
  setSuccess,
} from "../store/actions/notify.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let initialState = "";
// const navigate =useHistory();

//   useEffect (()=>{
//     navigate.push('/coin-address');

//   },[0])

const CoinAddress = () => {
  const navigate =useHistory();

  useEffect (()=>{
    navigate.push('coin-address');

  },[0])

  const [tokenAddress, setTokenAddress] = useState(initialState);
  const [object, setObject] = useState("");
  const [otp, setOtp] = useState("");

  const [isNew, setIsNew] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  const user = useSelector((state) => selectUser(state));
  const dispatch = useDispatch();

  console.log(isLoading);

  const handleCreate = async () => {
    try {
      setIsLoading(true);

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp)
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );

      const result = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );

      if (!result.data) return dispatch(setFailure("Please enter valid OTP"));

      const response = await axios.post("/user/freeCoinAddress/create", {
        freeCoinAddress: tokenAddress,
        userId: user?.id,
      });
      if (response.status === 200) {
        setIsEditMode(!isEditMode);
        setOtp("");
        setOtpSent(false);
        dispatch(setSuccess("Free coin address created"));
        setTimeout(() => {
          dispatch(removeSuccess());
        }, 3000);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);

      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }

      if (!otp)
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
        );

      const result = await axios.get(
        `${constant.otp.verify}/${user.id}/${otp}`
      );

      if (!result.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }

      const response = await axios.put(
        `/user/editFreeCoinAddress/${tokenAddress}`,
        object
      );
      if (response.status === 200 && response.data.data) {
        setObject(response.data.data);
        setIsEditMode(!isEditMode);
        setOtpSent(false);
        setOtp("");
        dispatch(setSuccess("Free coin address updated"));
        setTimeout(() => {
          dispatch(removeSuccess());
        }, 3000);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("USDT Address")), []);

  useEffect(() => {
    const fetchFreeCoin = async () => {
      try {
        const response = await axios.get(`/user/getFreeCoinAddress/${user.id}`);

        if (response.data.data) {
          setObject(response.data.data);
          setTokenAddress(response.data.data.freeCoinAddress);

          initialState = response.data.data.freeCoinAddress;
          setIsNew(false);
          setInitialLoading(false);
        } else {
          setInitialLoading(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchFreeCoin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading....</p>;
  }

  return (
    <div className="card-container">
      <div className="header__container--1">
        <button
          type="button"
          className={isEditMode ? "cancel__btn" : "edit__btn dark__blue"}
          onClick={() => {
            setIsEditMode(!isEditMode);
            isEditMode && setTokenAddress(initialState);
          }}
          disabled={isLoading}
        >
          {isEditMode ? "Cancel" : "Edit Profile"}
        </button>
        {isEditMode && (
          <button
            type="button"
            className="edit__btn dark__pink"
            onClick={isNew ? handleCreate : handleUpdate}
            disabled={isLoading}
          >
            {isLoading ? "Loading" : isNew ? "Create" : "Update"}
          </button>
        )}
      </div>
      <div>
        <div className="form_list">
          <label className="form_title">(USDT) address</label>
          <input
            type="text"
            placeholder={!isEditMode ? "-" : "Enter Coin Address"}
            name="name"
            value={tokenAddress}
            onChange={(e) => setTokenAddress(e.target.value)}
            className="form_box"
            disabled={!isEditMode}
          />
        </div>
        {otpSent && (
          <div className="form_list">
            <label className="form_title">OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="form_box"
              disabled={!isEditMode}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoinAddress;
