import React, { useState } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

import NavBar from "../components/NavBar";
import TopBar from "../components/TopBar";
import MenuDrawer from "../components/MenuDrawer";
import Alerter from "../components/Alerter";
import { selectUser } from "../store/reducers/auth.reducers";
import routes from "../data/routes";
import newyear from "../assets/new-year.jfif"
import {  useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Dashboard = () => {
  // const navigate =useHistory();

  // useEffect (()=>{
  //   navigate.push('/withdraw');

  // },[0])
  const [isShrink, setIsShrink] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [popup, setPopup] = useState(true)

  const { success, failure } = useSelector((state) => state.notify);
  const user = useSelector((state) => selectUser(state));

  // const newYear = 

  const handleShrink = () => setIsShrink(!isShrink);
  const handleDrawerOpen = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <div className="main-container">
      {/* { popup &&
      <div className="pop-up" onClick={() => setPopup(!popup)}>
        <div className="pop-up-container">
        <img src={newyear} alt="ss" className="pop-up-image"/>
        <p className="pop-up-text">Assetfmdc Team wishes you a very Happy New Year...!</p>
        </div>
      </div>
      } */}
      <NavBar isShrink={isShrink} />
      <div className={`${isShrink ? "right--shrink" : "right"} full-width`}>
        <TopBar
          handleShrink={handleShrink}
          handleOpenDrawer={handleDrawerOpen}
        />
        {routes.map(({ path, component }) => (
          <Route path={path} component={component} exact key={path} />
        ))}
        <MenuDrawer
          onClose={handleDrawerOpen}
          visible={isDrawerOpen}
          id={user?.refId}
        />
        <Alerter visible={failure} message={failure} type="error" />
        <Alerter visible={success} message={success} type="success" />
      </div>
    </div>
  );
};

export default Dashboard;
